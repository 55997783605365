import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import jihen from "assets/img/faces/jihen.jpg";
import mohamed from "assets/img/faces/mohamed.png";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Here is our team</h2>
      <div>
        <GridContainer>
          <GridItem lg={6} xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem
                lg={6}
                xs={12}
                sm={12}
                md={6}
                className={classes.itemGrid}
              >
                <img src={jihen} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Jihen Aouni
                <br />
                <small className={classes.smallTitle}>COO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Entrepreneur and Philanthropist, Jihen is seeking the best of
                  different cultures from around the world and sharing that with
                  others - she is always excited to deliver only the highest
                  quality and value.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem lg={6} xs={12} sm={12} md={6}>
            <Card plain>
              <GridItem
                lg={6}
                xs={12}
                sm={12}
                md={6}
                className={classes.itemGrid}
              >
                <img src={mohamed} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Mohamed Mohamed
                <br />
                <small className={classes.smallTitle}>CEO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Entrepreneur and Site Reliability Engineer.{" "}
                  <a href="https://mohamedphd.com"> Mohamed</a> is always
                  looking for new challenges and opportunities to excel and
                  provide high quality services.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
