import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";

import styles1 from "assets/jss/material-kit-react/views/profilePage.js";

import { cardTitle } from "assets/jss/material-kit-react.js";

import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import olive1 from "assets/img/olive1.jpg";
import oil6 from "assets/img/oil6.jpg";
import oil5 from "assets/img/oil5.jpg";

const styles = {
  ...styles1,
  ...imagesStyles,
  cardTitle
};
const useStyles = makeStyles(styles);
export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk product</h2>
          <h5 className={classes.description}>
            We are selecting the finest olive oils from Tunisia. We are making
            sure that every step of the production is following the best
            practices to offer you the joy of savouring the olive oil.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <img
                style={{ height: "180px", width: "100%", display: "block" }}
                className={classes.imgCardTop}
                src={olive1}
                alt="Card-img-cap"
              />
              <CardBody>
                <h4 className={classes.cardTitle}>Harvest</h4>
                We make sure that the olives are harvested in the best timing to
                get the most of their health benefits. The right timing allows
                to have a lower acidity and more health benefits.
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <img
                style={{ height: "180px", width: "100%", display: "block" }}
                className={classes.imgCardTop}
                src={oil6}
                alt="Card-img-cap"
              />
              <CardBody>
                <h4 className={classes.cardTitle}>Pressing</h4>
                Once harvested, the olives are transported to be cold pressed in
                the shortest delays to keep them fresh. The olives are pressed
                only once to keep the purity of the oil.
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <img
                style={{ height: "180px", width: "100%", display: "block" }}
                className={classes.imgCardTop}
                src={oil5}
                alt="Card-img-cap"
              />
              <CardBody>
                <h4 className={classes.cardTitle}>Storage</h4>
                To keep the oil fresh and maintaining its health benefits, we
                make sure that the recipients are dark enough to prevent the
                light from getting in. Our storage is meant to extend the life
                and quality of the oil.
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
